.doodle-container {
  display: grid;
  grid-template-columns: 10% 1fr 10%;
}

@media (max-width: 767px) {
  .about-container {
    grid-template-columns: 7% 1fr 7%;
  }
}

.left-of-doodle {
  min-height: 100%;
  background: linear-gradient(to right,
      rgba(24, 255, 255, 0.1),
      rgba(24, 255, 255, 0.01),
      transparent);
  border-left: 2px solid var(--cyber-blue);
}

.right-of-doodle {
  min-height: 100%;
  background: linear-gradient(to left,
      rgba(24, 255, 255, 0.1),
      rgba(24, 255, 255, 0.01),
      transparent);
  border-right: 2px solid var(--cyber-blue);
}

.canvas-container {
  border: 2px solid var(--cyber-blue);
}

.left-of-canvas {
  background: linear-gradient(to left,
  rgba(24, 255, 255, 0.1),
  rgba(24, 255, 255, 0.01),
  transparent);
}

.right-of-canvas {
  background: linear-gradient(to right,
  rgba(24, 255, 255, 0.1),
  rgba(24, 255, 255, 0.01),
  transparent);
}

.clear-button-default {
  color: var(--robogrey);
  cursor: default;
}

.clear-button-active {
  color: var(--cyber-orange);
  filter: drop-shadow(0px 0px 10px var(--cyber-orange));
}

.button-cluster-container {
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 767px) {
  .button-cluster-container {
    grid-template-columns: repeat(1, 1fr)
  }
}

.button-cluster {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

.button-inactive,
.button-active,
.button-disabled {
  appearance: none;
  display: flex;
  align-items: center;
  padding-left: 3px;
  padding-right: 3px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -webkit-font-smoothing: subpixel-antialiased;
}

.button-inactive {
  cursor: pointer;
  color: var(--light);

}

.button-active {
  cursor: pointer;
  color: var(--cyber-blue);
  filter: drop-shadow(0px 0px 10px var(--cyber-blue));
}

.button-inactive:hover {
  font-weight: bold;
  color: var(--cyber-green);
}

.button-disabled {
  cursor: default;
  color: var(--dark-robogrey);
}

.button-content {
  font-family: "Roboto Mono";
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-bullet {
  list-style-type: none;
}

.custom-bullet li {
  background-image: url("../pieces/bots/protobot.png");
  background-repeat: no-repeat;
  background-position: 0 50%;
  padding-left: 2.5em; /* Adjust this value to control the space between the bullet and the text */
}
