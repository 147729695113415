/* .rb-explore {
  flex: 1;
  display: flex;
  flex-direction: column;
} */

.rb-explore-ui {
  width: 300px;
  min-width: 300px;
  height: auto;
  overflow: auto;
  border-right: var(--border1);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.rb-explore-ui::-webkit-scrollbar {
  display: none;
}

.explore-searchbar-content {
  width: 300px;
}

.panel-toggle {
  display: none; /* only displayed on mobile */
  background-color: var(--back2);
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  outline: none;
  z-index: 10;
}

.panel-toggle img {
  width: 20px;
  height: 20px;
}

/* For mobile devices */
@media screen and (max-width: 767px) {
  .rb-explore-container {
    position: relative;
  }

  .rb-explore-ui {
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    z-index: 5;
    transition: left 0.3s ease;
    background-color: var(--back1);
  }

  .rb-explore-ui.panel-open {
    left: 0;
  }

  .panel-toggle {
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    transition: left 0.9s ease;
    border: 3px solid var(--cyber-green);
  }

  .panel-toggle.panel-open {
    left: calc(100% - 50px);
  }

  .explore-searchbar-content {
    display: block;
  }

}

/* =========================== */
/* UI Elements on ExplorePanel */
/* =========================== */

button.clear-search {
  font-weight: normal; /* reset font-weight */
}

button.clear-search:hover {
  font-weight: bold;
  transition-property: font-weight;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;
}

#bot-type-search:focus,
#bot-attribute-search:focus,
#bot-min-price:focus,
#bot-max-price:focus,
#bot-digit-search:focus {
  outline: none;
  border: none;
  box-shadow: 0 0 0 2px var(--cyber-blue);
}

li.dropdown-selection {
  border-bottom: 1px solid white;
}

li.dropdown-selection:hover {
  background-color: #cbd5e0;
}

.range-slider-container {
  padding: 0 32px;
}

.range-slider-row {
  display: flex;
  align-items: center;
}

.range-slider-wrapper {
  flex-grow: 1;
  position: relative;
}

.range-slider {
  width: 100%;
  height: 16px;
  padding: 30px 0;
}

.range-slider::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 3px;
  background-color: var(--cyber-blue);
  pointer-events: none;
}

.range-slider-selected {
  position: absolute;
  top: 50%;
  height: 3px;
  background-color: var(--cyber-green);
  pointer-events: none;
  z-index: 10;
}

.range-slider-thumb {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--cyber-green);
  cursor: grab;
  outline: none;
}

.range-slider-thumb::before {
  position: absolute;
  content: attr(data-value);
  top: -1.8em;
  left: 0.4em;
  font-size: 0.7em;
  color: var(--cyber-green);
}

.range-slider-track {
  top: 50%;
  background-color: var(--cyber-blue);
  height: 3px;
}

.range-slider-label {
  position: absolute;
  top: -1.8em;
  left: 0.4em;
  font-size: 0.7em;
  color: var(--cyber-green);
}

.range-slider-min-label {
  font-size: 0.7em;
  left: 0;
  top: 2em;
  margin-right: 8px;
}

.range-slider-max-label {
  font-size: 0.7em;
  right: 0;
  top: 2em;
  margin-left: 8px;
}

.checkbox {
  border: 2px solid var(--cyber-blue);
}

.digit-search-input::placeholder {
  color: rgba(0, 0, 0, 0.15);
}

button.search-button {
  cursor: pointer;
  outline: none;
  background-color: transparent;
  display: flex;
  border: 3px solid var(--cyber-green);
  color: var(--cyber-green);
  padding: 8px 20px;
}