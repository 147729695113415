.about-container {
  display: grid;
  grid-template-columns: 17% 1fr 17%;
  height: 100%;
  width: 100%;
  overflow: auto;
}

@media (max-width: 767px) {
  .about-container {
    grid-template-columns: 7% 1fr 7%;
  }
}

.left-of-about {
  min-height: 100%;
  background: linear-gradient(to right,
      rgba(255, 95, 188, 0.1),
      rgba(255, 95, 188, 0.01),
      transparent);
  border-left: 2px solid var(--cyber-pink);
}

.right-of-about {
  min-height: 100%;
  background: linear-gradient(to left,
      rgba(255, 95, 188, 0.1),
      rgba(255, 95, 188, 0.01),
      transparent);
  border-right: 2px solid var(--cyber-pink);
}

.about-separator {
  width: 20%;
  border-top: 1px solid var(--light);
}

.about-summary-header {
  filter: drop-shadow(0px 0px 10px var(--cyber-pink));
}

.summary-line {
  margin: 6px;
  font-size: 18px;
  text-align: center;
}

@media (max-width: 767px) {
  .summary-line {
    margin: 4px;
    font-size: 14px;
  }
}

.guide-link:hover {
  filter: drop-shadow(0px 0px 10px var(--cyber-pink));
}

.email-form-label {
  margin-top: 8px;
  margin-bottom: 2px;
  color: var(--light);
  font-size: 14px;
}

#name:focus,
#email:focus,
#message:focus,
.custom-checkbox:focus {
  outline: none;
  border: none;
  box-shadow: 0 0 0 2px var(--cyber-pink);
}

.custom-checkbox {
  background-color: var(--back2);
  border: 1px solid var(--robogrey);
  border-radius: 3px;
}

.custom-checkbox:checked,
.custom-checkbox:hover:checked,
.custom-checkbox:focus:checked {
  background-color: var(--cyber-pink);
}

.hide-recaptcha {
  display: none;
}

.grecaptcha-badge {
  display: none;
}

.form-submit:hover {
  color: var(--cyber-pink);
  filter: drop-shadow(0px 0px 10px var(--cyber-pink));
}

.form-submit-disabled {
  color: var(--back2);
  cursor: not-allowed;
}