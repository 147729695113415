/* ================== */
/* RobobotView styles */
/* ================== */

button.arrow-button:hover {
  font-weight: bold;
  color: var(--cyber-pink);
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
}

.pixelated {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-pixelated;
  image-rendering: pixelated;
}