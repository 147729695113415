.account-container {
  display: grid;
  grid-template-columns: 17% 1fr 17%;
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .account-container {
    grid-template-columns: 7% 1fr 7%;
  }
}

.account-view {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.left-of-account {
  min-height: 100%;
  background: linear-gradient(
    to right,
    rgba(118, 255, 3, 0.1),
    rgba(118, 255, 3, 0.01),
    transparent
  );
  border-left: 2px solid var(--cyber-green);
}

.right-of-account {
  min-height: 100%;
  background: linear-gradient(
    to left,
    rgba(118, 255, 3, 0.1),
    rgba(118, 255, 3, 0.01),
    transparent
  );
  border-right: 2px solid var(--cyber-green);
}

.account-bot-grid {
  grid-auto-columns: minmax(0, max-content);
  gap: 0.5rem;
  justify-items:start;
}

.redeem-button,
.redeem-button-disabled {
  background-color: var(--back1);
  font-size: 11px;
}

.redeem-button {
  color: var(--cyber-green);
  border: 2px solid var(--cyber-green);
}

.redeem-button:hover {
  background-color: var(--cyber-green);
  color: var(--back1);
  border: 2px solid var(--cyber-green);
}

.redeem-button-disabled {
  color: var(--robogrey);
  border: 2px solid var(--robogrey);
  cursor: default;
}

.page-button-disabled {
  color: var(--robogrey);
  cursor: default;
}