:root {
  --back1: #353535;
  --back2: #444444;
  --light: #E5E5E5;
  --robogrey: #bdbdbd;
  --dark-robogrey: #6d6d6d;
  --cyber-green: #76ff03;
  --cyber-blue: #18ffff;
  --cyber-pink: #ff5fbc;
  --cyber-yellow: #f4ff03;
  --cyber-orange: #ff8c03;
  --cyber-red: #ff0e03;
  --overlay1: rgba(0, 0, 0, 0.7);
  --border1: 1px solid rgba(255, 255, 255, 0.25);
  --font-primary: "Roboto Mono";
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

body {
  overscroll-behavior: contain;
  /* scrollbar-width: none; (Firefox 64+)
  -ms-overflow-style: none; (IE and Edge) */
}

a {
  text-decoration: none;
}

.logo-image {
  background-image: url("./pieces/misc/protobot.png");
  display: inline-block;
  width: 48px;
  height: 48px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.rb-home-title:hover .logo-image,
.rb-home-title-active:hover .logo-image {
  background-image: url("./pieces/misc/protobot_lasers.png");
}

.rb-home-title-active {
  cursor: default;
}

button {
  cursor: pointer;
  outline: none;
  background-color: transparent;
  display: flex;
  color: var(--front1);
  padding: 0px;
  font-family: var(--font-primary);
}

button.transaction-button {
  cursor: pointer;
  outline: none;
  background-color: var(--back1);
  display: flex;
  border: 2px solid var(--cyber-green);
  color: var(--cyber-green);
  padding: 6px;
  font-weight: normal; /* reset font-weight */
}

button.submit-button {
  cursor: pointer;
  outline: none;
  background-color: var(--back1);
  display: flex;
  border: 2px solid var(--cyber-blue);
  color: var(--cyber-blue);
  padding: 6px;
  font-weight: normal; /* reset font-weight */
}

button.cancel-button {
  cursor: pointer;
  outline: none;
  background-color: var(--back1);
  display: flex;
  border: 2px solid var(--cyber-pink);
  color: var(--cyber-pink);
  padding: 6px;
  font-weight: normal; /* reset font-weight */
}

button.transaction-button-disabled {
  cursor: pointer;
  outline: none;
  background-color: transparent;
  display: flex;
  border: 2px solid var(--robogrey);
  color: var(--robogrey);
  padding: 6px;
  font-family: var(--font-primary);
  font-weight: normal;  /* reset font-weight */
  pointer-events: none;
}

button.transaction-button:hover {
  border-width: 4px; /* increase border thickness on hover */
  font-weight: bold; /* make text bold on hover */
}

button.close-button:hover {
  font-weight: bold;
  transition-property: font-weight;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;
}

.rb-site-container {
  font-family: "Roboto Mono";
}

.rb-home-button {
  background-color: var(--back1);
  color: white;
}

.rb-home-button-active {
  background-color: var(--robogrey);
  color: var(--back1);
  pointer-events: none;
}

.rb-home-button:hover {
  background-color: var(--back2);
}

.rb-home-button img {
  width: 24px;
  height: 24px;
  display: none;
}

/* show image for active button */
.rb-home-button-active img {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 8px;
}

/* when hovering, display image regardless of active status */
.rb-home-button:hover img,
.rb-home-button-active:hover img {
  display: inline-block;
  margin-right: 8px;
}

/* Add this media query for mobile devices */
@media screen and (max-width: 767px) {
  .rb-home-button,
  .rb-home-button-active {
    padding: 8px 8px;
    font-size: 12px;
  }

  .rb-home-title,
  .rb-home-title-active {
    font-size: 0; /* Hides the text by setting font-size to 0 */
  }

  .rb-home-title .logo-image,
  .rb-home-title-active .logo-image {
    margin-right: 0; /* Removes the margin to the right of the logo */
  }

  .header-full {
    padding-left: 20px;
    padding-right: 8px;
  }

  .rb-home-button-active img {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }

  .rb-home-button img {
    width: 20px;
    height: 20px;
  }
}

.header-title {
  margin-right: 16px;
}

.app-content {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.header-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* Remove up/down arrow buttons for number input fields */
/* For Chrome, Safari, and Edge */
input.no-arrows::-webkit-outer-spin-button,
input.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input.no-arrows {
  -moz-appearance: textfield;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal {
  border-radius: 8px;
  padding: 1rem;
  width: 80%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-close-button {
  border: none;
  border-radius: 4px;
  color: var(--light);
  cursor: pointer;
  margin-top: 0.5rem;
  padding: 8px 16px;
}

.modal-close-button:hover {
  background-color: var(--back2);
}

/* A DIY 'truncate' tailwind class, as the original was not working */
.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bg-row-even {
  background-color: rgba(255, 255, 255, 0.05);
}

.bg-row-odd {
  background-color: rgba(125, 125, 125, 0.05);
}